import React from 'react'

import Layout from '../components/layout'
import Parallax from '../components/parallax'

const NotFound = () => {
  return (
    <Layout title="Page Not Found">
      <Parallax background="404.png" />
    </Layout>
  )
}

export default NotFound
